/**
 * PageLayout is a page wrapper providing a specified or default header
 * and a footer.
 * Supported props: config ctx currentpath header theme fpOn fpOff
 */

import React from 'react';
import Nav from './NavToggleFlex';
import FixedPanel from './FixedPanel';
import Footer from './Footer';
import { CookieProvider } from './CookieContext';
import { setLinks } from '../util/pagetools';
import { localizer } from '../util/localizer';
import logo from '../images/upstate_logo.png';

export default function PageLayout(props) {
  const cfg = props.config;
  const localized = localizer(props.ctx);
  const toplinks = (cfg.sections ? setLinks(cfg.sections, true) : null);
  const footdata = {
    ...cfg.footer,
    toplinks,
    sitename: cfg.sitename,
    siteurl: cfg.siteurl
  };
  const mainstyle = (props.theme ? {className: props.theme} : {});
  const cookieConfig = (cfg.homepage ? cfg.homepage.cookie : cfg.cookie);
  const defaultHeader = (
    <>
      <Nav toplinks={toplinks}
           sitename={cfg.sitename}
           theme="bg-white"
           ctx={props.ctx}
           currentpath={props.currentpath}
           localizerfn={localized}
      />
      {props.fpOn && <FixedPanel src={cfg.fixedpanel} btnLabel={cfg.cta} visibleBelow={props.fpOn} visibleAbove={props.fpOff} />}
    </>
  );

  return (
    <div id="page">
      <CookieProvider content={cookieConfig}>
        <header>{props.header || defaultHeader}</header>
        <main {...mainstyle}>{props.children}</main>
        <Footer src={footdata} localizerfn={localized} />
        <div className="hpad-10p">
          <img src={logo} alt="Upstate Global Health" className="w-200 py-16" />
        </div>
      </CookieProvider>
    </div>
  );
};
