import React from 'react';
import { Link, graphql } from 'gatsby';
import { localizer } from '../util/localizer';
import Layout from '../components/PageLayout';

export default function PageNotFound(props) {
  const ctx = props.pageContext;
  const localized = localizer(ctx);
  const sitedata = props.data.homePage;

  return (
    <Layout config={sitedata} ctx={ctx} currentpath={props.path}>
      <div className="message-page white-on-pary">
        <h1>{sitedata.errorpage.header}</h1>
        <h2>{sitedata.errorpage.subheader}</h2>
        <div>
          <Link to={localized('/')} className="std-btn btn-white-on-light">{sitedata.homelink}</Link>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query site404Q($locale: String) {
    homePage: siteYaml(locale: {eq: $locale}) {
      homelink
      sitename
      siteurl
      errorpage {
        header
        subheader
      }
      footer {
        text
        theme
        socialLinkLabel
        links {
          label
          to
        }
      }
      cookie {
        text
        linkText
        accept
        reject
      }
    }
  }`;
